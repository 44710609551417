import * as React from 'react';
//import './Login.scss';
//import { history } from '../../../App';
import { DefaultProps } from 'components/_utils';
interface Props extends DefaultProps {
  children?: any;
  location?: any;
}


export const Error: React.FC = (props: Props) => {

  return (
    <div className="error-page">
      <h1>Adastria Portal Error</h1>
      <h4>{props.location.state.msg}</h4>
    </div>
  );
};

export default Error;
