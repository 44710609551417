import * as React from 'react';
import './MenuItem.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
interface Props extends DefaultProps {
  children?: string;
  selectedItem?: string;
  onClick?: any;
  isCard?: boolean;
}

export const MenuItem = (props: Props) => (
  <button
    className={`${mapModifiers('m-menuitem', props.modifiers)} ${
      props.selectedItem === props.children ? 'm-menuitem--active' : ''
      }`}
    onClick={e => {
      if (props.isCard) {
        e.preventDefault();
      }
      props.onClick(props.children);
    }}
  >
    <div>{props.children}</div>
  </button>
);

export default MenuItem;

MenuItem.defaultProps = {
  onClick: () => { }
};
