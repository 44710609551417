import * as React from 'react';
import './Tab.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import Notify from 'components/atoms/Notify/Notify';
interface Props extends DefaultProps {
  children?: any;
  active?: boolean;
  hasNew?: boolean;
  onClick?: any;
}

export const Tab = (props: Props) => {
  const [hasNew, setHasNew] = React.useState(!props.active && props.hasNew ? true : false);
  return (
    <button
      className={`${mapModifiers('m-tab', props.modifiers)} ${props.active ? 'm-tab--active' : ''}`}
      onClick={() => {
        setHasNew(false);
        props.onClick(props.children);
      }}
    >
      <div>
        {props.children}
        {!props.active && hasNew ? (
          <span className="m-tab__notify">
            <Notify modifiers={['8']} />
          </span>
        ) : (
          ''
        )}
      </div>
    </button>
  );
};

export default Tab;

Tab.defaultProps = {
  onClick: () => {}
};
