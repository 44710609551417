import * as React from 'react';
import './Modal.scss';
import { DefaultProps } from 'components/_utils';
import Button from 'components/atoms/Button/Button';
interface Props extends DefaultProps {
  close?: any;
  children?: any;
}

export const Modal = (props: Props) => (
  <div className={'o-modal'}>
    <div className="o-modal__wrapper">
      <div className="o-modal__wrapper__close">
        <Button typeIcon="close-menu" modifiers={['']} size="18" onClick={() => props.close(false)} />
      </div>
      {props.children}
    </div>
  </div>
);

export default Modal;
