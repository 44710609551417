import * as React from 'react';
import './Notify.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
interface Props extends DefaultProps {
  children?: any;
  // props that come into the component
}

export const Notify = (props: Props) => <span className={mapModifiers('a-notify', props.modifiers)} />;

export default Notify;
