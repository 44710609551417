import * as React from 'react';
import './Card.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';

interface Props extends DefaultProps {
  type?: CARD_TYPE;
  children?: any;
}
export type CARD_TYPE = 'weather' | 'sales' | 'approved' | 'tasks' | 'store' | 'notice';
export const Card = (props: Props) => (
  <div className={mapModifiers('o-card', [props.type])}>
    <div className="wrapper">{props.children}</div>
  </div>
);

export default Card;
