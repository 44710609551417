import * as React from 'react';
import './Pickup.scss';
import * as comFunc from 'common/functions';

const PICKUP_API_URL = process.env.REACT_APP_API_SERVER + "/pickup/getlist";
const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME); //自動更新の時間

export const Pickup = () => {
  const [loading, setLoading] = React.useState(true);
  const [pickupArr, setPickupArr] = React.useState([]);

  React.useEffect(() => {
    // Pickupの設定
    let getPickup = async () => {
      // APIから取得
      let data = await comFunc.callAPI(PICKUP_API_URL);

      if (data.length > 0) {
        // 横3列になるように埋める
        let add_num = (3 - data.length % 3) % 3;
        for (let i = 0; i < add_num; i++) {
          data.push([]);
        }
        setPickupArr(data);
      }
      setLoading(false);
    }
    getPickup();

    // 自動更新
    const autoUpdatingId = setInterval(() => getPickup(), UPDATE_TIME);
    return () => clearInterval(autoUpdatingId);
  }, []);

  return (
    <div className="o-pickup">
      {loading ? <div>loading...</div>
        :
        <ul>
          {pickupArr.map((pickup, index) => (
            <li key={index}>
              {pickup.image ?
                <div className="o-pickup__contents">
                  <div className="o-pickup__contents__banner">
                    {pickup.image ?
                      <a href={pickup.link} target="_blank" rel="noreferrer noopener">
                        <img alt="NoImage" src={"data:image/gif;base64," + pickup.image} />
                      </a>
                      : ""
                    }
                  </div>
                  <div className="o-pickup__contents__footer">
                    {pickup.title}
                  </div>
                </div>
                :
                <div></div>
              }
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default Pickup;


