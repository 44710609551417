import React, { useEffect } from 'react';
import './TableauCardContent.scss';

const tableau = require('tableau-api-js');
const TableauViz: React.FC = () => {
	let viz: tableau.Viz | null = null; // vizをuseEffectの外で宣言
  
	//<script type='module' src='https://jpp-tableau.adastria.co.jp/javascripts/api/tableau.embedding.3.latest.min.js'></script>
	//<tableau-viz id='tableau-viz' src='https://jpp-tableau.adastria.co.jp/views/_16698826321570/sheet4' width='550' height='201' hide-tabs toolbar='hidden' ></tableau-viz>

	useEffect(() => {
	  const initTableau = () => {
		const containerDiv = document.getElementById('tableauContainer');
		const url = 'https://jpp-tableau.adastria.co.jp/views/_16698826321570/sheet4';
  
		const options: tableau.VizCreateOptions = {
		  hideTabs: true,
		  hideToolbar: true,
		  onFirstInteractive: () => {
			console.log('onFirstInteractive');
		  },
		};
  
		viz = new tableau.Viz(containerDiv, url, options); // vizを更新
	  };
  
	  const script = document.createElement('script');
	  script.src = 'https://jpp-tableau.adastria.co.jp/javascripts/api/tableau.embedding.3.latest.min.js';
	  script.async = true;
	  script.onload = initTableau;
	  document.body.appendChild(script);
  
	  return () => {
		if (viz) {
		  viz.dispose();
		}
	  };
	}, []);
  
	return (
	  <div id="tableauContainer" style={{ width: '550px', height: '201px' }}>
		{/* コンテナ */}
	  </div>
	);
  };
  
  export default TableauViz;