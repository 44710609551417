import * as firebase from 'firebase';
import * as React from 'react';
import './MenuList.scss';
import VerticalMenu from 'components/molecules/VerticalMenu/VerticalMenu';
import * as comFunc from 'common/functions';

interface State {
  lstGyomu: any[],
  lstSinsei: any[],
  lstEtc: any[],
  lstFavorite: any[],
  user: any,
}


const MENU_API_URL = process.env.REACT_APP_API_SERVER + "/menu/list/{user_id}";
// お気に入り追加削除
const FAV_REGIST_URL = process.env.REACT_APP_API_SERVER + "/menu/addfav";
const FAV_DELETE_URL = process.env.REACT_APP_API_SERVER + "/menu/delfav";
const FAV_REORDER_URL = process.env.REACT_APP_API_SERVER + "/menu/reorderfav";
const MORE_NUM = 8;
const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME); //自動更新の時間 #44
const WAM_SOURCE_URL = process.env.REACT_APP_WAM_DOMAIN + "/pxpadmin/bin/user_welcome.htm?floatingBox=default&callback=wamresponse";
const WAM_RECERTIFICATION_URL = process.env.REACT_APP_WAM_RECERTIFICATION;

// FIFOを実現するためのキュー
class Queue {
  data: any;
  constructor() {
    this.data = [];
  }
  enqueue = (item: any) => this.data.push(item);
  dequeue = () => this.data.shift();
  first = () => {
    if (this.data.length > 0) {
      return this.data[0];
    }
    return null;
  }
  length = () => this.data.length;
}

class MenuList extends React.Component<{}, State> {

  addFavQueue: Queue; // お気に入り追加実行用キュー
  autoUpdateIntervalId: NodeJS.Timeout;

  constructor(props: any) {
    super(props);
    this.state = {
      lstGyomu: [],
      lstSinsei: [],
      lstEtc: [],
      lstFavorite: [],
      user: null,
    }

    this.getMenuData = this.getMenuData.bind(this);
    this.setMenuList = this.setMenuList.bind(this);

    this.addFavoriteGyomu = this.addFavoriteGyomu.bind(this);
    this.addFavoriteSinsei = this.addFavoriteSinsei.bind(this);
    this.addFavoriteEtc = this.addFavoriteEtc.bind(this);
    this.delFavorite = this.delFavorite.bind(this);
    this.sortFavorite = this.sortFavorite.bind(this);
    this.setStarButtonStatus = this.setStarButtonStatus.bind(this);

    this.addFavQueue = new Queue();
  }

  componentDidMount() {

    // WAM取得リソースを業務メニューにマージする
    // WAMからはapp.tsx内で<div id=wamdata>内にリソースをとってきている
    this.getMenuData();

    // 自動更新
    this.addWamCallBack();
    this.autoUpdateIntervalId = setInterval(() => {
      let wam_url = comFunc.checkEnvValue(WAM_SOURCE_URL);
      wam_url = wam_url.replace("wamresponse", "updatewamresponse");
      comFunc.loadScript(wam_url);
    }, UPDATE_TIME);
  }

  componentWillUnmount() {
    clearInterval(this.autoUpdateIntervalId);
  }

  // メニュー情報取得処理
  async getMenuData() {
    try {
      // ユーザのお気に入り情報とあわせて、メニュー一覧を取得する。
      let user = firebase.auth().currentUser;
      let user_id; // メールアドレスをユーザIDとして扱う
      if (user && user.email && user.emailVerified) {
        user_id = user.email;
      } else {
        alert("ログイン情報の取得に失敗しました。\n再度ログインから行ってください。")
        return;
      }
      // メニュー一覧＋ログイン中ユーザのお気に入りも取得
      let url = MENU_API_URL.replace("{user_id}", user_id);
      let apiData = await comFunc.callAPI(encodeURI(url));

      // 突合時用に、お気に入り(group_id=0)だけ抜き出す
      // （戻ってきたデータ内でお気に入りは順番を保持しているため順に詰めていく）
      let favData = [];
      for (let i = 0; i < apiData.length; i++) {
        if (apiData[i].group_id === "0") {
          favData.push(apiData[i]);
        }
      }
      // WAMの情報取得
      // app.tsxで<div id=wamdata>に埋め込んでおいたデータを抜き取る
      let wamData: any[] = [];
      let val = document.getElementById("wamdata");
      if (val.textContent !== "") {
        wamData = JSON.parse(val.textContent);
        val.textContent = ""; // json領域はクリア
      }

      // APIとWAMの情報を突合
      let menuData: any[] = [];

      // すべてのメニューリスト追加（お気に入り以外）
      for (let i = 0; i < apiData.length; i++) {
        if (apiData[i].group_id === "0") {
          continue;
        }
        for (let j = 0; j < wamData.length; j++) {
          // キー項目が同じである
          if (apiData[i].cn === wamData[j].service_cn) {
            let name = apiData[i].dsp_name.length > 0 ? apiData[i].dsp_name : apiData[i].cn;
            let url = apiData[i].url.length > 0 ? apiData[i].url : wamData[j].url; // URLはaws上のものにする
            menuData.push({
              name: name,
              cn: apiData[i].cn, // 保存されるのはCN
              url: url,
              group_id: apiData[i].group_id,
              isFavorite: 0, // 白星ボタン
            });
            wamData.splice(j, 1);
            break;
          }
        }
      }

      // お気に入りのメニューを追加
      // ※表示可能なメニューに該当するお気に入りを表示
      let favcount = 0;
      let favMenuData: any[] = [];
      for (let i = 0; i < favData.length; i++) {
        for (let j = 0; j < menuData.length; j++) {
          if (favData[i].cn === menuData[j].cn) {
            favMenuData.push({
              id: ++favcount, // 並び替え用
              name: menuData[j].name,
              cn: menuData[j].cn,
              url: menuData[j].url,
              group_id: "0",
              isFavorite: 1, // 黄色星ボタン
              target_group: menuData[j].group_id,
            });
            menuData[j].isFavorite = 1;
            break;
          }
        }
      }
      // メニューリストを作成
      this.setMenuList(menuData, favMenuData);
    }
    catch {
    }
  }

  // メニューリストの設定
  setMenuList(menuData: any, favMenuData: any) {
    let gyomuArr: any = [];
    let sinseiArr: any = [];
    let etcArr: any = [];
    let favoriteArr: any = [];

    for (let i = 0; i < menuData.length; i++) {
      if (menuData[i].group_id === "1") {
        gyomuArr.push(menuData[i]);
      }
      if (menuData[i].group_id === "2") {
        sinseiArr.push(menuData[i]);
      }
      if (menuData[i].group_id === "3") {
        etcArr.push(menuData[i]);
      }
    }
    // お気に入り
    for (let i = 0; i < favMenuData.length; i++) {
      favoriteArr.push(favMenuData[i]);
    }

    this.setState({
      lstGyomu: gyomuArr,
      lstSinsei: sinseiArr,
      lstEtc: etcArr,
      lstFavorite: favoriteArr,
    });
  }

  // キューイングした処理をfifoで実行する
  exec = async (queue: Queue) => {
    try {
      // キューにあるだけ実行する
      while (true) {
        let execinfo: any = queue.first(); // fifo
        if (execinfo) {
          await execinfo.func(execinfo.args); // メソッド実行
          queue.dequeue(); // 実行情報をキューから削除する
        } else {
          break; // 処理がなくなったら終了
        }
      }
    } catch (e) {
      //console.log(e);
    }
  }

  // お気に入り追加処理
  // 順番が狂わないように、キューを使って直列実行する
  // name : 項目名(cn)
  // lst : お気に入りを押した欄のメニューリスト（業務/申請/その他）（通常８件＋さらに表示）
  // id:それらの種別(業務:1、申請:2、その他:3）
  addFavorite = (name: string, lst: any, id: string) => {
    // お気に入り元のメニューのボタンを戻す
    this.setStarButtonStatus(id, name, true);

    let execinfo = { // 関数実行情報
      func: this.doAddFavorite,
      args: [name, lst, id]
    };
    this.addFavQueue.enqueue(execinfo);
    if (this.addFavQueue.length() > 1) {
      // キューにある場合は先行スレッドに処理を委ねる。
      //console.log("over")
      return;
    }
    this.exec(this.addFavQueue);
  }

  // お気に入り追加の実処理。
  // 子で指定されたお気に入り項目を左袖、aws上のDBに追加する
  doAddFavorite = async (args: any[]) => { // キューに追加した引数をメソッド内で展開
    let name: string = args[0];
    let lst: any[] = args[1];
    let id: string = args[2];

    // すでにお気に入りにあるかチェックして、あるなら追加しない
    let favArr = this.state.lstFavorite;
    for (let i = 0; i < favArr.length; i++) {
      if (favArr[i].name === name) {
        return;
      }
    }

    // 指定されてきた名前に合致するurlを元のリストから取ってきて、お気に入りに追加する
    for (let i = 0; i < lst.length; i++) {
      if (lst[i].name === name) {
        // お気に入り項目の変更
        let obj = {
          //id: favArr.length + favArrMore.length + 1, // 子での並び替え用
          id: favArr.length + 1, // 子での並び替え用
          name: name,
          url: lst[i].url,
          cn: lst[i].cn,
          group_id: id,
          isFavorite: 1,
          target_group: lst[i].group_id,
        };

        // 追加してお気に入りリストのみ更新
        favArr.push(obj);
        this.setState({ lstFavorite: favArr });

        // お気に入り項目を管理するaws上のDBを更新（項目＋並び順）
        let user_id = firebase.auth().currentUser.email; // メールアドレスをユーザIDとして扱う
        await comFunc.httpPostJson(FAV_REGIST_URL, JSON.stringify({
          cn: lst[i].cn, // 保存するのはcn
          user_id: user_id
        }));
        return; // 追加したら終了
      }
    }
  }

  // 業務システム欄のお気に入り追加
  addFavoriteGyomu = (name: string) => {
    this.addFavorite(name, this.state.lstGyomu, "1");
  }

  // 申請系欄のお気に入り追加
  addFavoriteSinsei = (name: string) => {
    this.addFavorite(name, this.state.lstSinsei, "2");
  }

  // その他欄のお気に入り追加
  addFavoriteEtc = (name: string) => {
    this.addFavorite(name, this.state.lstEtc, "3");
  }

  // 子でお気に入り削除を押した場合に左袖、aws上のDBから削除する
  delFavorite = async (name: string, group: string) => {
    // 通常8件か、さらに表示に入っているかを確認する。
    // 入っている場所を確認したら、リストを作り直す
    // あわせてDBに登録するCNを取得しておく
    let favArr = this.state.lstFavorite;
    let cn = "";
    // 新しくセットするリスト
    let newFavArr = [];
    // 指定されてきた削除対象以外をリストに追加する
    for (let i = 0; i < favArr.length; i++) {
      if (favArr[i].name !== name) {
        newFavArr.push(favArr[i]);
      } else {
        cn = favArr[i].cn;
      }
    }

    if (cn === "") {
      // 念のため
      alert("お気に入りの削除に失敗しました。\nもう一度削除ボタンを押してください。");
      return;
    }
    // お気に入り欄の更新
    this.setState({ lstFavorite: newFavArr });

    // お気に入り元のメニューのボタンを戻す
    this.setStarButtonStatus(group, name, false);

    // お気に入り項目を管理するaws上のDBを更新（項目削除）
    // 削除対象のcnをaws上から削除する
    let user_id = firebase.auth().currentUser.email; // メールアドレスをユーザIDとして扱う
    await comFunc.httpPostJson(FAV_DELETE_URL, JSON.stringify({
      cn: cn,
      user_id: user_id
    }));
  }

  // 子でD&Dの並び替えを行った場合に呼ばれる
  // name:表示名、number:指定した表示名の新しい順番（０スタート）
  sortFavorite = async (name: string, newidx: number) => {
    let targetfav = null;
    // 最初に名前に合致する要素を拾ってくる
    let totalLstFavorite = this.state.lstFavorite;
    for (let i = 0; i < totalLstFavorite.length; i++) {
      if (totalLstFavorite[i].name === name) {
        targetfav = totalLstFavorite[i];
        break;
      }
    }

    // これを指定位置に差し込む
    let beforeTarget = false; // 該当要素の前か
    let beforeTargetFav: any = []; // 該当要素の前までの一時配列
    let exist = false; // 見つかったらtrue
    let newFavAll = []; // 差し込んだ後の結果の配列
    for (let i = 0, idx = 0; ; i++) { // idx:元の要素の現在の位置
      if ((beforeTargetFav.length + newFavAll.length) === newidx) {
        // 該当する位置が来たらそこに差し込む
        for (let j = 0; j < beforeTargetFav.length; j++) {
          // 差し込む前に、追加保留にしていた要素を結果配列に追加
          newFavAll.push(beforeTargetFav[j]);
        }
        newFavAll.push(targetfav);
        beforeTarget = false;
        exist = true;

      } else if (totalLstFavorite[idx] !== targetfav) {
        if (beforeTarget) {
          // target登場前であれば追加保留要素によけておく
          beforeTargetFav.push(totalLstFavorite[idx]);
        } else {
          newFavAll.push(totalLstFavorite[idx]);
        }
        idx = idx + 1;
      } else {
        // 要素一致
        if (!exist) {
          beforeTarget = true; // 新しいindexが出現するまでは追加保留
        }
        idx = idx + 1;
      }
      if (newFavAll.length === totalLstFavorite.length) {
        break; // すべて詰めたらそこで終了
      }
    }
    // これを分割
    let forJson = []; // AWS保存用の全ての要素の配列
    for (let i = 0; i < newFavAll.length; i++) {
      // 送信データ "cn":cn, "num":新しい順番
      forJson.push({ cn: newFavAll[i].cn, num: i + 1 });
    }

    // お気に入り欄の更新
    this.setState({ lstFavorite: newFavAll });

    // AWS保存 すべての名前と順番を送る
    // cn, numberの配列{[cn:xxx, num:1],[cn:yyy, num:2],...}
    let user_id = firebase.auth().currentUser.email; // useridをメールアドレスとして扱う
    await comFunc.httpPostJson(FAV_REORDER_URL, JSON.stringify({
      cn_num_json: JSON.stringify(forJson),
      user_id: user_id
    }));
  }

  // メニューの星ボタンを設定する
  setStarButtonStatus = (group: string, name: string, status: boolean) => {
    // お気に入り元のメニューのボタンを戻す
    let menuData: any[] = [];
    if (group === "1") {
      menuData = this.state.lstGyomu;
    } else if (group === "2") {
      menuData = this.state.lstSinsei;
    } else if (group === "3") {
      menuData = this.state.lstEtc;
    }
    for (let i = 0; i < menuData.length; i++) {
      if (menuData[i].name === name) {
        menuData[i].isFavorite = status ? 1 : 0;
        break;
      }
    }
    if (group === "1") {
      this.setState({ lstGyomu: menuData });
    } else if (group === "2") {
      this.setState({ lstSinsei: menuData });
    } else if (group === "3") {
      this.setState({ lstEtc: menuData });
    }
  }

  //Wamデータ取得コールバック関数
  addWamCallBack = () => {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');

    // WAM情報の取得コールバック関数をscriptに追加
    // statusをupdated_wam_statusに代入しクリックイベントで判定処理を実行
    script.text =
      "function updatewamresponse(json) {" +
      "  const services = json.services;" +
      "  const element = document.getElementById('wamdata');" +
      "  if (element.textContent === '') {" +
      "    element.textContent = JSON.stringify(services);" +
      "    document.getElementById('updated_wam_status').value = json.status;" +
      "    document.getElementById('updated_wam_status').click();" +
      "  }" +
      "}";

    head.appendChild(script);
  };

  //WAMステータスチェック処理
  checkWamStatus = (e: any) => {
    let status = e.target as HTMLInputElement;
    if (status.value === "error") {
      comFunc.redirect(WAM_RECERTIFICATION_URL);

    } else {
      this.getMenuData();
    }
  }

  public render() {
    return (
      <div className="o-menulist">
        <input type="hidden" id="updated_wam_status" onClick={this.checkWamStatus} />

        <div className="top">
          <VerticalMenu title="お気に入り" icon="star" id="menuFavorite" list={this.state.lstFavorite.slice(0, MORE_NUM)}
            morelist={this.state.lstFavorite.slice(MORE_NUM)} isFavorite onDelFavCallBack={this.delFavorite}
            onSortFavCallBack={this.sortFavorite} />
        </div>
        <div className="middle">
          <VerticalMenu title="業務システム" icon="lap" id="menuGyomu" list={this.state.lstGyomu.slice(0, MORE_NUM)}
            morelist={this.state.lstGyomu.slice(MORE_NUM)} onAddFavCallBack={this.addFavoriteGyomu} />
        </div>
        <div className="middle">
          <VerticalMenu title="申請系" icon="file" id="menuShinsei" list={this.state.lstSinsei.slice(0, MORE_NUM)}
            morelist={this.state.lstSinsei.slice(MORE_NUM)} onAddFavCallBack={this.addFavoriteSinsei} />
        </div>
        <div className="bottom">
          <VerticalMenu title="その他" icon="other" id="menuEtc" list={this.state.lstEtc.slice(0, MORE_NUM)}
            morelist={this.state.lstEtc.slice(MORE_NUM)} onAddFavCallBack={this.addFavoriteEtc} />
        </div>
      </div >
    );
  }
}
export default MenuList;
