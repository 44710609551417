import * as React from 'react';
import './HeadingList.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import Icon, { TYPES_ICON } from 'components/atoms/Icons/Icon';
import Text from 'components/atoms/Text/Text';
interface Props extends DefaultProps {
  children?: any;
  type?: TYPES_ICON;
  isList?: boolean;
  onClick?: any;
}

export const HeadingList = (props: Props) => (
  <div className={mapModifiers('m-headinglist', props.modifiers)} onClick={() => props.onClick()}>
    <div className={mapModifiers('m-headinglist__title', [props.type ? props.type : ''])}>
      {props.type ? <Icon type={props.type} /> : ''}
      <Text modifiers={props.isList ? ['bold', '16'] : ['16']}>{props.children}</Text>
    </div>
    {/* {props.isList ? <Button typeIcon="arrow-up" modifiers={['icon', 'arrow']} /> : ''} */}
  </div>
);

export default HeadingList;

HeadingList.defaultProps = {
  onClick: () => {}
};
