import * as React from 'react';
import './NoticeCardContent.scss';
import * as comFunc from 'common/functions';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface States {
  notices: any[],
  run_flg: boolean,
}

const NOTICES_API_URL = process.env.REACT_APP_API_SERVER + "/notice/list";
const SLIDE_DELAY = 5 * 1000; // スライドは5秒ごと
const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME); //自動更新の時間

class NoticeCardContent extends React.Component<{}, States> {
  slider: any;
  intervalId: NodeJS.Timeout;
  autoUpdateIntervalId: NodeJS.Timeout;

  constructor(props: any) {
    super(props);

    this.state = {
      notices: [],
      run_flg: false,
    }
  }

  componentDidMount() {
    this.getNotice().then(() => {
      // 無駄なタイマーを防ぐため一つしかないときはかけない
      if (this.state.notices.length > 1) {
        this.slideStart();
      }
    });
    this.autoUpdateIntervalId = setInterval(() => this.getNotice(), UPDATE_TIME);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.autoUpdateIntervalId);
  }

  // メニュー情報取得処理
  async getNotice() {
    try {
      // APIの情報取得
      let noticeData = await comFunc.callAPI(NOTICES_API_URL);
      if (noticeData === null || noticeData.length === 0) {
        // 何もお知らせなかったら固定文言
        noticeData = [{
          notice_content: "<p>※現在お知らせはありません</p>",
          publish_start_at: "",
          contributor_name: ""
        }];
      }
      this.setState({ notices: noticeData, run_flg: true });
    }
    catch {
    }
  }

  slideStart = () => {
    this.intervalId = setInterval(() => {
      this.slider.slickNext();
    }, SLIDE_DELAY);
  }

  // 日付をyyyy-mm-dd形式に
  formatDate = (datestr: string) => {
    if (datestr === null || datestr === "") {
      return datestr;
    }
    let dt = new Date(datestr);
    let year = dt.getFullYear().toString();
    let month = (("00" + (dt.getMonth() + 1).toString()).slice(-2));
    let date = (("00" + (dt.getDate().toString())).slice(-2));
    return year + "/" + month + "/" + date;
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dotsClass: 'notice-myDots',
      afterChange: () => {
        clearInterval(this.intervalId);
        this.slideStart();
      },
    };

    return (
      <div className='m-noticecardcontent' >
        {this.state.run_flg ?
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {this.state.notices.map((item: any, index: number) => {
              let key = index;
              return (
                <div key={key} className='m-noticeblock'>
                  <div className="contents" dangerouslySetInnerHTML={{ __html: item.notice_content }}>
                  </div>
                  <div className="footer">
                    <div className="footer__left">{this.formatDate(item.publish_start_at)}</div>
                    <div className="footer__right" >{item.contributor_name}</div>
                  </div>
                </div>)
            })}
          </Slider>
          : <div>Nowloding...</div>
        }

      </div >
    );
  }
};

export default NoticeCardContent;
