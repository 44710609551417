/**
 * 共通関数群
 */

/**
 * Cookieの取得
 * @param name Cookie名称
 */
export function getCookie(name: string) {
  return ((document.cookie + ";").match("adp-" + name + "=([^¥S;]*)") || [])[1];
}

/**
 * Cookieの保存
 * @param name Cookie名称
 * @param data 値
 * @param limit_sec 期限(デフォルトで30日)
 */
export function setCookie(name: string, data: string, limit_sec: string = "2592000") {
  let str_cookie = "adp-" + name + "=" + data + ";";
  if (limit_sec !== "") {
    str_cookie += " max-age=" + limit_sec;
  }
  document.cookie = str_cookie;
}

/**
 * APIのGET呼出
 * @param _url APIのURL
 */
export async function callAPI(_url: string) {
  let result: any = null;

  // Fetchで取得
  await fetch(_url, { method: "GET" })
    .then((response) => {
      if (!response.ok) {
        let err_msg = response.status.toString() + ":" + response.statusText;
        throw Error(err_msg);
      }
      result = response.json();
    })
    .catch((err) => {
      console.error(err);
      return null;
    });

  return result;
}

// json文字列のPOST
export async function httpPostJson(_url: string, _json: string) {
  let result: any = null;
  await fetch(_url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: _json
  })
    .then((response) => {
      if (!response.ok) {
        let err_msg = response.status.toString() + ":" + response.statusText;
        throw Error(err_msg);
      }
      result = response.json();
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
  return result;
}

/**
 * Date型を指定フォーマットで日付文字列に変換
 * @param _date 変換元
 * @param _format フォーマット文字列
 */
export function convertDateString(_date: Date, _format: string = "Y/m/d") {
  let year = _date.getFullYear().toString();
  let month = "0" + (_date.getMonth() + 1).toString();
  let date = "0" + _date.getDate().toString();
  let hours = "0" + _date.getHours().toString();
  let minutes = "0" + _date.getMinutes().toString();
  return _format.replace("Y", year)
    .replace("m", month.slice(-2))
    .replace("d", date.slice(-2))
    .replace("h", hours.slice(-2))
    .replace("i", minutes.slice(-2));
}

// env値を各ステータスに沿って変更
export function checkEnvValue(env: string) {
  let rtnVal = env;

  // ログアウトによる変更
  let logout = getCookie("logout-status") === "1";
  let port = process.env.REACT_APP_LOGOUT_PORT;
  rtnVal = rtnVal.replace("[logout_port]", logout ? ":" + port : "");

  return rtnVal;
}

/**
 * ドメインチェック
 * @param email
 */
export async function checkAllowedDomain(email: string) {
  let url = process.env.REACT_APP_API_SERVER + "/domain/check/{email}";
  url = url.replace("{email}", email);

  console.log("domain check url:" + url);
  let result = await callAPI(encodeURI(url));

  if (result.status === "success") {
    return true;
  } else {
    console.log("domain check NG");
    setCookie("ban_domain", "1", "5");
    return false;
  }
}

/**
 * 相対時間の取得
 * @param befDate 対象日時
 * @param aftDate 算出基準日時
 */
export function calcRelativeTime(befDate: Date, aftDate: Date) {
  let rtnVal = "";
  let past_sec = aftDate.getTime() - befDate.getTime();
  past_sec = Math.floor(past_sec / 1000);

  let min_sec = 60;
  let hour_sec = 60 * 60;
  let day_sec = 60 * 60 * 24;
  let year_sec = 60 * 60 * 24 * 365;

  if (past_sec <= 0) {
    rtnVal = "0秒前";

  } else if (past_sec < min_sec) {
    rtnVal = past_sec + "秒前";

  } else if (past_sec < hour_sec) {
    rtnVal = Math.floor(past_sec / min_sec) + "分前";

  } else if (past_sec < day_sec) {
    rtnVal = Math.floor(past_sec / hour_sec) + "時間前";

  } else if (past_sec < year_sec) {
    rtnVal = Math.floor(past_sec / day_sec) + "日前";

  } else {
    rtnVal = Math.floor(past_sec / year_sec) + "年前";
  }

  return rtnVal;
}

/**
 * スクリプト読込
 * @param src        URL
 * @param errorFunc　エラーイベント
 */
export function loadScript(src: any, errorFunc: any = null) {
  var head = document.getElementsByTagName('head')[0];
  var script: any = document.createElement('script');
  script.src = src;
  head.appendChild(script);

  script.onload = function () {
    if (document.readyState === "complete") {
      script.onload = null;
      script.onerror = null;
      if (head && script.parentNode) {
        head.removeChild(script);
      }
    }
  };
  script.onerror = errorFunc;
}

/**
 * リダイレクト
 */
export function redirect(url: string) {
  // WAM再認証回数をCookieで管理(5sだけ保存)
  let retry: number = 0;
  if (getCookie("wam-retry")) {
    retry = Number(getCookie("wam-retry"));
  }
  // 再認証URLへリダイレクト（１回まで実行）
  if (retry < 1) {
    setCookie("wam-retry", String(retry + 1), "5");

    window.location.href = checkEnvValue(url);
  }
}

/**
 * Googleアナリティクスイベントトラッキング oshima add 2020/11/30
 */
declare let gtag: any; //gtagが認識されないので一度型宣言をする
export function executeGtagEventTracking(event_category: string, event_label: string) {
  let uid = "undifined uid";
  let mail = "undifined mail";

  //メールアドレス
  const user_info = document.getElementsByClassName('m-user-info__text')[0];
  mail = user_info.children[0].textContent;

  //wamユーザー情報
  const wam_userdata = document.getElementById('wam_userdata').textContent;
  if (wam_userdata != "") {
    const array_userdata = JSON.parse(wam_userdata);
    uid = array_userdata.LPF_USER_LDAP_UID;
  }

  //コンパイル後にGoogleアナリティクスのgtag関数を実行
  gtag('event', 'click', { 'event_category': event_category, 'event_label': event_label, 'dimension1': uid, 'dimension2': mail });
}

