import * as React from 'react';
import './BbsGadget.scss';
import Tab from 'components/molecules/Tab/Tab';
interface State {
  readonly category?: string;
}

const categoryList = ['新着', '本部通達', '人事', '経理'];
const forumIdList = ['1080005', '1170001', '30002', '10003'];
const CookaiURL = "https://bbs.coo-kai.jp/a/g.adastria.co.jp/gadget/newUiGadget";
const paramDefault: any = {
  tenant: "g.adastria.co.jp",
  gadgetType: "newtopic",
  forum1Id: "1080005",
  gadgetHeight: "400px",
  label: "true",
  days: "365",
  titleBgColor: "ffffff",
  titleTextColor: "050505",
  contentTitleColor: "050505",
  contentTextColor: "373737",
  contentSpaceLine: "7px",
  newTextColor: "f00000",
  fontType: "Arial"
};
const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME); //自動更新の時間

class BbsGadget extends React.Component<{}, State> {
  autoUpdateIntervalId: NodeJS.Timeout;

  constructor(props: any) {
    super(props);

    this.state = {
      category: categoryList[0]
    }
    this.tabClick = this.tabClick.bind(this);
  }

  componentDidMount() {
    this.tabClick(this.state.category);
    this.autoUpdateIntervalId = setInterval(() => this.updateIFrame(), UPDATE_TIME);
  }

  componentWillUnmount() {
    clearInterval(this.autoUpdateIntervalId);
  }

  // iflameの自動更新
  updateIFrame() {
    let preFrames = document.getElementsByClassName("bbs-frame");
    for (let i = 0; i < preFrames.length; i++) {
      let preFrame = preFrames[i].children[0] as HTMLIFrameElement;
      // srcに''を追加しリロードする
      preFrame.src += '';
    }
  }

  tabClick(item: string) {
    this.setState({ category: item });

    var frames = document.getElementsByClassName("bbs-frame");
    for (var i = 0; i < frames.length; i++) {
      var frame = frames[i] as HTMLDivElement;
      if (frame.className.match(item)) {
        frame.hidden = false;
      } else {
        frame.hidden = true;
      }
    };
  }

  createUrl(chgParam: any) {
    var paraArr: string[] = [];
    for (var key in paramDefault) {
      var val = paramDefault[key];
      for (var k in chgParam) {
        if (key === k) {
          val = chgParam[k];
        }
      }
      paraArr.push(key + "=" + val);
    }
    return CookaiURL + "?" + paraArr.join("&");
  }

  public render() {
    var tabItems = categoryList;
    var urlArr: any[] = [];
    for (var i = 0; i < tabItems.length; i++) {
      urlArr.push(this.createUrl({ forum1Id: forumIdList[i] }));
    }

    return (
      <div className="o-bbs">
        <div className="o-bbs__tablist">
          {tabItems.map(item => (
            <Tab active={item === this.state.category} onClick={this.tabClick} key={item}>
              {item}
            </Tab>
          ))}
        </div>
        {tabItems.map((item, index) => (
          <div className={"bbs-frame " + item} key={index}>
            <iframe src={urlArr[index]} title={"title" + index} />
          </div>
        ))}
      </div>
    );
  };
}

export default BbsGadget;
