import * as React from 'react';
import { history } from '../../App';
import './layout.scss';
import { DefaultProps } from 'components/_utils';
import Header from 'components/organisms/Header/Header';
import UserInfo from 'components/molecules/UserInfo/UserInfo';
import HeadingList from 'components/molecules/HeadingList/HeadingList';
import FunctionButton from 'components/atoms/FunctionButton/FunctionButton';
import MenuList from 'components/organisms/MenuList/MenuList';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as comFunc from 'common/functions';
import RightIcons from 'components/molecules/RightIcons/RightIcons';

interface Props extends DefaultProps, RouteComponentProps {
  children?: any;
  user?: any;
}

const logout = () => {
  history.push('/logout');
};

export const LayOut = (props: Props) => {
  const getSelected = () => {
    const pathName = props.location.pathname.split('/')[1];
    return pathName === '' ? '本部' : pathName === 'store' ? '店舗' : 'メディア';
  };
  const [menuShow, setMenuShow] = React.useState(true);

  setClickEvent();

  return (
    <div className="layout">
      <Header openMenuFnc={setMenuShow} selectedItem={getSelected()} />
      <div className="body">
        <div className={`body__leftmenu ${menuShow ? 'body__leftmenu--show' : ''}`}>
          <div className="body__leftmenu__profile">
            <div className="body__leftmenu__profile__info">
              <UserInfo user={props.user} />
            </div>
          </div>
          <div className="body__leftmenu__menu">
            <MenuList />
            <HeadingList type={'logout'} modifiers={['logout']} onClick={() => logout()}>
              ログアウト
            </HeadingList>
          </div>
          <div className="body__signature">© Adastria Co., Ltd.</div>
        </div>
        {!menuShow ? (
          <div className="body__content">
            <div className="body__content__function-list">
              <div className="body__content__function-list__left">
                {/* メール */}
                <div className="item gtag-btn" data-category="main_link" data-label="メール">
                  <FunctionButton href="https://mail.google.com/" modifiers={['mail']} />
                  <div className="item__option">
                    <div className="item__option__wrapper">
                      <a href="https://mail.google.com/" target="_blank" rel="noopener noreferrer">
                        <span>メール</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/* カレンダー */}
                <div className="item gtag-btn" data-category="main_link" data-label="カレンダー">
                  <FunctionButton href="https://calendar.google.com/" modifiers={['calendar']} />
                  <div className="item__option">
                    <div className="item__option__wrapper">
                      <a
                        href="https://calendar.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-category="main_link"
                        data-label="カレンダー"
                      >
                        <span>カレンダー</span>
                      </a>
                      <a
                        href="https://pc.touch-mee.net/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-category="main_link"
                        data-label="touch-mee"
                      >
                        <span>touch-mee</span>
                      </a>
                      <a
                        href="https://yoyaku.ic-guarding.biz/OfficeView/OfficeView.WebPages/LoginPage.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-category="main_link"
                        data-label="ヒカリエ来訪者予約"
                      >
                        <span>ヒカリエ来訪者予約</span>
                      </a>
                      <a
                        href="https://reception.secom.jp/daiba-frontier/OfficeView/OfficeView.WebPages/LoginPage.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-category="main_link"
                        data-label="台場オフィス来訪者予約"
                      >
                        <span>台場オフィス来訪者予約</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/* フォルダ */}
                <div className="item">
                  <FunctionButton modifiers={['folder']} />
                  <div className="item__option">
                    <div className="item__option__wrapper">
                      <a
                        href="https://drive.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gtag-btn"
                        data-category="main_link"
                        data-label="Google Drive"
                      >
                        <span>Google Drive</span>
                      </a>
                      <a
                        href="https://ssolb01/PortalTaskMenu/SSO/SsoRedirectToInternal?redirectType=Internal.Cabinet2"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gtag-btn"
                        data-category="main_link"
                        data-label="キャビネット1"
                      >
                        <span>キャビネット1</span>
                      </a>
                      <a
                        href="https://ssolb01/PortalTaskMenu/SSO/SsoRedirectToInternal?redirectType=Internal.Cabinet"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gtag-btn"
                        data-category="main_link"
                        data-label="キャビネット2"
                      >
                        <span>キャビネット2</span>
                      </a>
                      <a
                        href="https://file.adastria.co.jp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gtag-btn"
                        data-category="main_link"
                        data-label="Aファイル便"
                      >
                        <span>Aファイル便</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/* ADASTRIA TV */}
                <div className="item gtag-btn" data-category="main_link" data-label="ADASTRIA TV">
                  <FunctionButton href="https://adastria-gc.coorum.jp/menus/gjkpszhrwwytwah2/announcements" modifiers={['video']} />
                  <div className="item__option">
                    <div className="item__option__wrapper">
                      <a href="https://adastria-gc.coorum.jp/menus/gjkpszhrwwytwah2/announcements" target="_blank" rel="noopener noreferrer">
                        <span>ADASTRIA TV</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/* チャットボット */}
                <div className="item gtag-btn" data-category="main_link" data-label="管理部運用チャットボット">
                    <FunctionButton href="https://app.hitto.chat/zxuQFR5nU2/XJO2rYpaat/chat" modifiers={['hakusyaku']} />
                    <div className="item__option">
						<div className="item__option__wrapper">
							<a href="https://app.hitto.chat/zxuQFR5nU2/XJO2rYpaat/chat" target="_blank" rel="noopener noreferrer">
							<span>管理部運用チャットボット</span>
							</a>
						</div>
                    </div>
                </div>
                {/* AGPT */}
                <div className="item gtag-btn" data-category="main_link" data-label="AGPT">
                    <FunctionButton href="https://www.adst01.smart-generative-chat.com" modifiers={['agpt']} />
                    <div className="item__option">
						<div className="item__option__wrapper">
							<a href="https://www.adst01.smart-generative-chat.com" target="_blank" rel="noopener noreferrer">
							<span>AGPT</span>
							</a>
						</div>
                    </div>
                </div>

                </div>
                <RightIcons />
            </div>
            {props.children}
            </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

function setClickEvent() {
  const targets = document.querySelectorAll(".gtag-btn"); //イベント対象のエレメント取得
  for (let i = 0; i < targets.length; i++) {
    targets[i].addEventListener('click', function () {
      comFunc.executeGtagEventTracking(targets[i].getAttribute('data-category'), targets[i].getAttribute('data-label'));
    });
    targets[i].classList.remove('gtag-btn'); //重複登録されないよう削除
  }
}

export default withRouter(LayOut);
