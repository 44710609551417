/* eslint-disable */
import * as React from 'react';
import './FunctionButton.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
interface Props extends DefaultProps {
  onClick?: any;
  href?: string;
}

export const FunctionButton = (props: Props) => (
  <a
    className={mapModifiers('a-function-button', props.modifiers)}
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
  ></a>
);

export default FunctionButton;
