export interface DefaultProps {
  modifiers?: string[];
}

export function mapModifiers(baseClassName: string, modifiers: string[] = []) {
  if (modifiers && !Array.isArray(modifiers)) {
    modifiers = [modifiers];
  }
  return modifiers.length > 0
    ? baseClassName +
        ' ' +
        baseClassName +
        '--' +
        modifiers.reduce(function(prev, next) {
          return prev + ' ' + baseClassName + '--' + next;
        })
    : baseClassName;
}
