/* eslint-disable */
import React from 'react';
import Card from 'components/organisms/Card/Card';
import './store.scss';
import StoreCardContent from 'components/molecules/StoreCardContent/StoreCardContent';

export const Store = () => (
  <div className="store-page">
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=F001&index=05000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>雇用契約管理</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://powerapps-bbs.appspot.com/a/g.adastria.co.jp/topic/294130001"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['drop', '5']} description="シーン別届け出帳票一覧表">
            各種届出
          </StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=D001&index=03000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>人事考課</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=F001&index=04000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>電子申請</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=C001&index=05000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['7']}>給与明細</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=C001&index=06000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>報奨金</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=D001&index=04000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>帰省手当</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E002&index=03500"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>用度品／テスター(新)</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=04000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent>タグ発注</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=01000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent>セール什器</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/SSO/SsoRedirectToInternal?redirectType=Internal.repair-tempo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['7']}>商品修理依頼</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=06000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>故障対応</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/SSO/SsoRedirectToInternal?redirectType=Internal.mainte-honbu"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent>内装メンテナンス</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=03000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent>ランプ</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=05000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['7']}>店舗監査報告</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=E001&index=07000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['6']}>店舗什器確認</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=A101&index=00010"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['6']}>売上予測</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=C001&index=01000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['5']}>店舗予算入力</StoreCardContent>
        </Card>
      </a>
    </div>
    <div className="store-page__group">
      <a
        href="https://ssolb01/PortalTaskMenu/JobMenu/OpenWindow?category=C001&index=08000"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent modifiers={['7']}>店舗月次報告</StoreCardContent>
        </Card>
      </a>
      <a
        href="https://sites.google.com/a/g.adastria.co.jp/portalsite9/rinku-ji"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card type="store">
          <StoreCardContent>関連リンク</StoreCardContent>
        </Card>
      </a>
    </div>
  </div>
);

export default Store;
