import * as React from 'react';
import { DefaultProps, mapModifiers } from 'components/_utils';
import './Button.scss';
import Icon, { TYPES_ICON } from '../Icons/Icon';

export interface BtnProps extends DefaultProps {
  children?: any;
  typeIcon?: TYPES_ICON;
  size?: string;
  onClick?: any;
  href?: string;
}
export const Button = (props: BtnProps) => (
  <a
    className={mapModifiers('a-btn', props.modifiers)}
    onClick={() => props.onClick()}
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {props.children}
    {props.typeIcon ? <Icon modifiers={props.size ? [props.size] : ['']} type={props.typeIcon} /> : ''}
  </a>
);

export default Button;

Button.defaultProps = {
  typeIcon: '',
  modifiers: ['primary'],
  onClick: () => { }
};
