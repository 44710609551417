import * as firebase from 'firebase';
import * as React from 'react';
import './Header.scss';
import MenuItem from 'components/molecules/MenuItem/MenuItem';
import { history } from '../../../App';
import HambugerMenu from '../HambugerMenu/HambugerMenu';
import UserIcon from 'components/atoms/UserIcon/UserIcon';
import Button from 'components/atoms/Button/Button';
import { Link } from 'react-router-dom';
// import WeatherHeader from 'components/molecules/WeatherHeader/WeatherHeader';
import * as comFunc from 'common/functions';
interface Props {
  openMenuFnc?: any;
  selectedItem?: string;
}

const ATTENDANCE_URL = process.env.REACT_APP_ATTENDANCE;

const UPDATE_TIME = Number(process.env.REACT_APP_AUTO_UPDATE_TIME);

export const Header = (props: Props) => {
  const [selected, setSelected] = React.useState(props.selectedItem);
  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);
  props.openMenuFnc(selected === '業務');
  const [user, setUser] = React.useState({ displayName: '', email: '', photoURL: '' });
  const [isHelpDeskNew, setIsHelpDeskNew] = React.useState(false); // ヘルプデスク
  const [isCsNew, setIsCsNew] = React.useState(false);             // CS

  React.useEffect(() => {
    let cleanedUp = false;

    // 認証チェック
    const checkAuth = async (user: any) => {
      try {
        // ドメインチェック
        if (user && await comFunc.checkAllowedDomain(user.email)) {
          setUser(user);
        } else {
          history.push('/login');
        }
      }
      catch (error) {
        history.push({ pathname: '/error', state: { msg: "・AWSへ接続できません" } });
      }
    }

    const user = firebase.auth().currentUser;
    if (!cleanedUp) {
      checkAuth(user);
    }

    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  }, []);

  React.useEffect(() => {
    //新着管理
    const getContents = async () => {
      let data = await comFunc.callAPI(process.env.REACT_APP_API_SERVER + "/content/date");
      if (data) {
        setIsCsNew(isContentNew("cs", data["cs"]));
        setIsHelpDeskNew(isContentNew("help-desk", data["helpdesk"]));
      }
    }
    getContents();
    // 自動更新
    const autoUpdatingId = setInterval(() => {
      getContents();
    }, UPDATE_TIME);
    return () => clearInterval(autoUpdatingId);
  }, []);

  //新着判定
  const isContentNew = (name: string, update_date: string) => {

    const lastUpdateDate = comFunc.getCookie(name + '-update-date'); //更新日付のCookie
    const elements = document.getElementsByClassName(name); //対象の画像とaタグ
    const contents = Array.from(elements);

    contents.forEach(content => {
      content.setAttribute("data-updatedate", update_date); //data属性に更新日付をセット
    });

    //最終更新日付のCookieが存在しない または 更新日付が更新されている
    if (!lastUpdateDate || lastUpdateDate !== update_date) {
      return true; //新着とする
    }
    return false; //新着としない
  };

  //新着用のCookieを更新
  const setCookieUpdateDate = (ev: React.MouseEvent<HTMLElement>) => {
    let target = ev.currentTarget;
    //data属性が初期値の空文字以外の場合
    if (target.dataset.updatedate !== "") {
      //有効期限を1年とし、対象の更新日付をCookieにセット
      comFunc.setCookie(target.className + '-update-date', target.dataset.updatedate, "31536000");
    }
  };


  const [sp, setSp] = React.useState(false);
  React.useEffect(() => {
    const checkWidth = () => {
      // スマートフォンの場合、本部以外では天気カードは出さない
      let match = window.matchMedia(`(max-width: 768px)`);
      if (match.matches && !sp) {
        setSp(true);
      } else if (!match.matches && sp) {
        setSp(false);
      }
    }
    checkWidth(); // 幅をMediaQueryでチェック
    window.addEventListener("resize", checkWidth);

    const cleanup = () => {
      window.removeEventListener("resize", checkWidth);
    };
    return cleanup;
  }, [sp]);

  return (
    <div className="o-header">
      <div className={`o-header__logo ${hamburgerOpen ? 'o-header__logo--fixed' : ''}`}>
        <HambugerMenu open={hamburgerOpen} openFnc={setHamburgerOpen} />
        <div className="o-header__logo__image"></div>
        {process.env.REACT_APP_ENV_MODE === "staging" ? <div className="logo-staging">検証環境</div> : ""}
        <UserIcon url={user.photoURL} />
      </div>

      <div className="o-header__menu">
        <div className="o-header__menu__left">
          {Number(process.env.REACT_APP_MENU_TAB) & 1 ?
            <div className="o-header__menu__left__item">
              <Link to="/">
                <MenuItem selectedItem={selected} onClick={setSelected}>
                  本部
                </MenuItem>
              </Link>
            </div>
            : ""
          }
          {Number(process.env.REACT_APP_MENU_TAB) & 2 ?
            <div className="o-header__menu__left__item">
              <Link to="/store">
                <MenuItem selectedItem={selected} onClick={setSelected}>
                  店舗
                </MenuItem>
              </Link>
            </div>
            : ""
          }
          {Number(process.env.REACT_APP_MENU_TAB) & 4 ?
            <div className="o-header__menu__left__item">
              <Link to="/media">
                <MenuItem selectedItem={selected} onClick={setSelected}>
                  メディア
                </MenuItem>
              </Link>
            </div>
            : ""
          }
          {/*
          <div className="o-header__menu__left__item">
            <MenuItem selectedItem={selected} onClick={setSelected}>
              業務
            </MenuItem>
          </div>
          */}
        </div>
        <div className="o-header__menu__right">
          {/* CSアイコン追加 レイアウト調整の為ヘルプデスク移動 oshima mdf #200 */}
          <div className="item">
            <div className={isCsNew ? "is-new" : ""}></div>
            <img
              alt="CS 総合ヘルプサイト"
              className="cs"
              src={require(`assets/imgs/cs_icon.png`)}
              data-updatedate=""
              onClick={(e) => {
                setCookieUpdateDate(e);
                setIsCsNew(false);
                comFunc.executeGtagEventTracking("main_link", "CS 総合ヘルプサイト");
                window.open("https://adastriahelp.zendesk.com/hc/ja");
              }}
            />
          </div>
          <div className="item">
            <div className={isHelpDeskNew ? "is-new" : ""}></div>
            <img
              alt="ヘルプデスク"
              className="help-desk"
              src={require(`assets/imgs/help_icon.png`)}
              data-updatedate=""
              onClick={(e) => {
                setCookieUpdateDate(e);
                setIsHelpDeskNew(false);
                comFunc.executeGtagEventTracking("main_link", "ヘルプデスク");
                window.open("https://sites.google.com/adastria.co.jp/gyoumushien/%E3%83%9B%E3%83%BC%E3%83%A0");
              }}
            />
          </div>
          <div className='button-item'>
            <Button href={comFunc.checkEnvValue(ATTENDANCE_URL)}>勤怠</Button>
          </div>
        </div>
      </div>

      {/* 勤怠メニューに重なるので移動 oshima del #200 */}
      {/* <div className="item">
        <div className={isHelpDeskNew ? "is-new" : ""}></div>
        <img
          alt="ヘルプデスク"
          className="help-desk"
          src={require(`assets/imgs/help_icon.png`)}
          data-updatedate=""
          onClick={(e) => {
            setCookieUpdateDate(e);
            setIsHelpDeskNew(false);
            comFunc.executeGtagEventTracking("main_link", "ヘルプデスク");
            window.open("https://sites.google.com/adastria.co.jp/gyoumushien/%E3%83%9B%E3%83%BC%E3%83%A0");
          }}
        />
      </div> */}

      {/*
        (selected === "本部" || !sp) ?
          <div className='o-header__weather' >
            <WeatherHeader />
          </div>
          : ""
      */}

    </div >
  );
};

export default Header;

Header.defaultProps = {
  openMenuFnc: () => { },
  selectedItem: '店舗'
};
