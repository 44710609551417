import * as firebase from 'firebase/app';
import * as React from 'react';
import './Logout.scss';
import * as comFunc from 'common/functions';

const LOGOUT_URL = {
  WAM: process.env.REACT_APP_LOGOUT_WAM,
  SSO: process.env.REACT_APP_LOGOUT_SSO,
  CLOUD_GATE: process.env.REACT_APP_LOGOUT_CLOUDGATE,
  GOOGLE: process.env.REACT_APP_LOGOUT_GOOGLE,
}
const LOGOUT_REDIRECT_URL = process.env.REACT_APP_LOGOUT_REDIRECT_URL;

export const Logout: React.FC = () => {
  React.useEffect(() => {
    // httpリクエストを送信
    let httpRequest = async (url: string) => {
      await fetch(url, {
        method: "GET",
        mode: 'no-cors', // cross site
        credentials: 'include' // cookieを送る
      }).catch(() => {
        return null;
      });
      return true;
    };
    // ログアウト処理（WAM → SSO → CloudGate → Googleアカウント → firebase）
    let logoutFunc = async () => {
      // WAM
      if (!await httpRequest(comFunc.checkEnvValue(LOGOUT_URL.WAM))) {
        return null;
      }
      // SSO
      if (!await httpRequest(LOGOUT_URL.SSO)) {
        return null;
      }
      // CloudGate
      if (!await httpRequest(LOGOUT_URL.CLOUD_GATE)) {
        return null;
      }
      // Google（Firebase）
      await firebase.auth().signOut().catch(() => {
        return null;
      });

      // Cookieにログアウトを残す
      comFunc.setCookie("logout-status", "1", "");
      comFunc.setCookie("wam-retry", "0", "");
      // リダイレクト
      window.location.href = LOGOUT_REDIRECT_URL;
      return true;
    }

    // ログアウト処理を実行
    logoutFunc();
  });

  return (
    <div className="logout">
      <div className="logout__centerblock">
        <div>
          <p>A</p>
          <div className="ball"></div>
          <p>&nbsp;</p>
        </div>
        <div>
          <p>D</p>
          <div className="ball"></div>
          <p>L</p>
        </div>
        <div>
          <p>A</p>
          <div className="ball"></div>
          <p>O</p>
        </div>
        <div>
          <p>S</p>
          <div className="ball"></div>
          <p>G</p>
        </div>
        <div>
          <p>T</p>
          <div className="ball"></div>
          <p>O</p>
        </div>
        <div>
          <p>R</p>
          <div className="ball"></div>
          <p>U</p>
        </div>
        <div>
          <p>I</p>
          <div className="ball"></div>
          <p>T</p>
        </div>
        <div>
          <p>A</p>
          <div className="ball"></div>
          <p>&nbsp;</p>
        </div>
      </div>
      <iframe title="googleLogoutFrame" src={LOGOUT_URL.GOOGLE} width="0" height="0" ></iframe>
    </div>
  );
};

export default Logout;
