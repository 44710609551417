import * as React from 'react';
import './UserInfo.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import Text from 'components/atoms/Text/Text';
import UserIcon from 'components/atoms/UserIcon/UserIcon';
interface UserInfoProps extends DefaultProps {
  user: any;
  // props that come into the component
}
export const UserInfo = (props: UserInfoProps) => (
  <div className={mapModifiers('m-user-info', props.modifiers)}>
    <UserIcon url={props.user.url} />
    <div className={'m-user-info__text'}>
      <div>
        <Text modifiers={['bold', '16']}>{props.user.latinName}</Text>
        {/*<Text modifiers={['bold', '12']}>さん</Text>*/}
      </div>
      {/*<Text modifiers={['italic', '12', 'sub']}>{props.user.latinName}</Text>*/}
    </div>
  </div>
);

export default UserInfo;
