import React, { useState } from 'react';
import './index.scss';
import BbsGadget from 'components/organisms/BbsGadget/BbsGadget';
import Card from 'components/organisms/Card/Card';
import TableauCardContent from 'components/molecules/TableauCardContent/TableauCardContent';
import Modal from 'components/organisms/Modal/Modal';
import NoticeCardContent from 'components/molecules/NoticeCardContent/NoticeCardContent';

export const Home = () => {
	const [openModal, setOpenModal] = useState(false);

	const [sp, setSp] = React.useState(false);
	React.useEffect(() => {
		const checkWidth = () => {
			// スマートフォンサイズかどうかをチェックし、その場合だけ天気欄のボックスを表示
			// 領域だけ確保し、実際の表示はヘッダ部の天気コンポーネントを使用
			let match = window.matchMedia(`(max-width: 768px)`);
			if (match.matches && !sp) {
				setSp(true);
			} else if (!match.matches && sp) {
				setSp(false);
			}
		}
		checkWidth(); // 幅をMediaQueryでチェック
		window.addEventListener("resize", checkWidth);

		const cleanup = () => {
			window.removeEventListener("resize", checkWidth);
		};
		return cleanup;
	}, [sp]);

	return (
		<div className="top-page">
			<div className="top-page__menu">
				<div className="top-page__menu__group top-page__menu__group--top">
					<div className="menu__item">
						<TableauCardContent />
					</div>
					<div className="menu__item">
						<Card type="notice">
							<NoticeCardContent />
						</Card>
						{openModal ? (
							<Modal close={setOpenModal}>
								<NoticeCardContent />
							</Modal>
						) : (
							''
						)}
					</div>
				</div>
			</div>
			<div className="top-page__news">
				{ /*<News />*/}
				<BbsGadget />
			</div>
		</div>
	);
};

export default Home;
