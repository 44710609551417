import * as React from 'react';
import './UserIcon.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
interface Props extends DefaultProps {
  url?: string;
}

export const UserIcon = (props: Props) => (
  <div className={mapModifiers('a-usericon', props.modifiers)}>
    <img alt="user" src={props.url} />
  </div>
);

export default UserIcon;
