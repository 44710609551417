import * as React from 'react';
import './Icon.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';

export type TYPES_ICON =
  | 'arrow-dot'
  | 'arrow-dot-white'
  | 'arrow-solid'
  | 'arrow-up'
  | 'bell'
  | 'calendar'
  | 'clock'
  | 'close'
  | 'close-menu'
  | 'chat-bot'
  | 'file'
  | 'folder'
  | 'hambuger'
  | 'lap'
  | 'light-bulb'
  | 'logout'
  | 'mail'
  | 'more'
  | 'people'
  | 'projectors'
  | 'star'
  | 'task'
  | 'tick'
  | 'time'
  | 'umbrella'
  | 'weather-1'
  | 'weather-2'
  | 'weather-3'
  | 'weather-4'
  | 'weather-5'
  | 'other';
interface Props extends DefaultProps {
  type?: TYPES_ICON;
  typeWeather?: any;
  onClick?: any;
  // props that come into the component
}

export const Icon = (props: Props) => (
  <img
    alt={props.typeWeather || props.type}
    className={mapModifiers('a-icon', props.modifiers)}
    src={require(`assets/imgs/${props.typeWeather || props.type}.svg`)}
    onClick={() => {
      if (props.onClick) {
        props.onClick()
      } else {
        return false;
      }
    }}
  />
);

export default Icon;
