import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const version = () => {
	return <p>{process.env.REACT_APP_BUILD_VERSION}</p>
}


const googleAnalytics = () => {
	var head = document.getElementsByTagName('head')[0];
	var script1: any = document.createElement('script');
	script1.async = true;
	script1.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

	var script2: any = document.createElement('script');
	var src = "";
	src += "  window.dataLayer = window.dataLayer || [];";
	src += "  function gtag() { dataLayer.push(arguments); }";
	src += "  gtag('js', new Date());";
	src += "  gtag('config', '" + process.env.REACT_APP_GOOGLE_ANALYTICS_ID + "');";
	script2.text = src;

	head.appendChild(script1);
	head.appendChild(script2);
}


const gtm = () => {
	return <noscript><iframe>{process.env.REACT_APP_BUILD_VERSION}</iframe></noscript>
}

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(version(), document.getElementById('build_version'));



googleAnalytics();





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
