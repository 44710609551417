import * as React from 'react';
import './LeftMenuItem.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import * as comFunc from 'common/functions';
interface Props extends DefaultProps {
  children?: any;
  isGroup?: boolean;
  url?: string;
  onEventCallBack?: any; // VerticalMenu:setChildShowMore
  onAddFavCallBack?: any; // お気に入り追加ボタン VerticalMenu:callAddFav
  onDelFavCallBack?: any; // お気に入り削除ボタン VerticalMenu:callDelFav
  isFavorite?: boolean;
  targetGroup?: string;
}

export const LeftMenuItem = (props: Props) => {
  const [show, setShow] = React.useState(false);
  // ★をクリック→お気に入り削除 お気に入り欄だけ使用する
  const delFavorite = () => {
    props.onDelFavCallBack(props.children, props.targetGroup);
  }
  // ☆をクリック→お気に入り追加 お気に入り欄以外が使用する
  const addFavorite = () => {
    props.onAddFavCallBack(props.children);
  }

  return props.isGroup ? (
    <div className={`${mapModifiers('a-leftmenu-item', props.modifiers)} a-leftmenu-item--group`}>
      <span
        className={mapModifiers('a-leftmenu-item--group__title', show ? ['show'] : [])}
        onClick={() => { setShow(true); props.onEventCallBack(true); }}
      >
        さらに表示
      </span>
      {
        show ? (
          <>
            {props.children}
            <span
              className={'a-leftmenu-item--group__title a-leftmenu-item--group__title--hidden'}
              onClick={() => { setShow(false); props.onEventCallBack(false); }}
            >
              表示を少なく
            </span>
          </>
        ) : (
          ''
        )
      }
    </div >
  ) : (
    <div className="a-leftmenu-menuitem">
      <a
        className={mapModifiers('a-leftmenu-item', props.modifiers)}
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        title={props.children}
        onClick={() => { comFunc.executeGtagEventTracking('menu_link', props.children) }}
      >
        <span>{props.children}</span>
      </a>
      {
        props.isFavorite ?
          <button type="button" className="btn-favorite added" onClick={delFavorite}>★</button>
          :
          <button type="button" className="btn-favorite" onClick={addFavorite}>★</button>
      }
    </div >
  );
};
export default LeftMenuItem;

LeftMenuItem.defaultProps = {
  url: 'https://ssolb01/PortalTaskMe'
};
