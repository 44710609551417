import * as React from 'react';
import { ReactSortable } from "react-sortablejs";
import './VerticalMenu.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import HeadingList from '../HeadingList/HeadingList';
import { TYPES_ICON } from 'components/atoms/Icons/Icon';
import * as comFunc from 'common/functions';
import LeftMenuItem from 'components/atoms/LeftMenuItem/LeftMenuItem';

interface Props extends DefaultProps {
  children?: any;
  title: string;
  icon?: TYPES_ICON;
  id: string; // cookieと紐づけるもの
  list?: any[];
  morelist?: any[]; // さらに表示する対象
  isFavorite?: boolean;
  onAddFavCallBack?: any; // 子LeftMenuItemのお気に入り追加ボタン。MenuList:addFavoriteXXX
  onDelFavCallBack?: any; // 子LeftMenuItemのお気に入り削除ボタン。MenuList:delFavorite
  onSortFavCallBack?: any; // 子LeftMenuItemのお気に入り並び替え。MenuList:sortFavorite
}

interface States {
  show: boolean; // この要素自体を表示するか
  childShowMore: boolean; // 子要素で「さらに表示」がある場合に表示するかどうか
  // ドラッグ中の状態
  favList: {
    id: number,
    name: string,
    cn: string,
    url: string,
    group_id: string
  }[];
  favMoreList: {
    id: number,
    name: string,
    cn: string,
    url: string,
    group_id: string
  }[];
}

const BASE_FONT_HEIGHT = 24; // １要素あたりの文字の高さ(px)

class VerticalMenu extends React.Component<Props, States> {

  constructor(props: Props) {
    super(props);
    // cookieから表示有無を取得
    let defaultSelect: string = comFunc.getCookie(props.id);
    if (defaultSelect == null) {
      defaultSelect = "1";
    }
    this.state = {
      show: defaultSelect === "1" ? true : false, //
      childShowMore: false, // 「さらに表示」は最初はoff
      favList: [], favMoreList: [] // ドラッグ中の状態をpropからコピー
    }
    this.clickAllow = this.clickAllow.bind(this);
    this.setChildShowMore = this.setChildShowMore.bind(this);

    this.callAddFav = this.callAddFav.bind(this);
    this.callDelFav = this.callDelFav.bind(this);

  }

  childHeight: any = {}; // 本欄の高さのCSS（アコーデオンするためにrenderで都度計算可変）
  dropAnother = false; // D&D関連 「さらに表示」領域内でのドロップ
  orderChanged = false; // D&D関連 順番変更の有無

  // ▽押した場合
  clickAllow = () => {
    this.setState({
      show: !this.state.show
    })
    comFunc.setCookie(this.props.id, !this.state.show ? "1" : "0");
  }

  // LeftMenuItemで「さらに表示」・「表示を少なく」を押した場合に子供からコールバックされるもの
  setChildShowMore = (childshowmore: boolean) => {
    this.setState({
      childShowMore: childshowmore
    })
  }

  // お気に入りに追加☆ボタンが押された処理
  callAddFav = (name: string) => {
    // 表示項目を管理している親をさらに呼び出し、追加処理を委譲
    if (!this.props.isFavorite) { // お気に入り欄には☆はないはずなので一応ブロック
      this.props.onAddFavCallBack(name);
    }
  }

  // お気に入り削除★ボタンが押された処理
  callDelFav = (name: string, group: string) => {
    if (this.props.isFavorite) { // お気に入り欄以外には★はないはずなので一応ブロック
      // 表示項目を管理している親をさらに呼び出し、削除処理を委譲
      if (this.state.childShowMore && this.props.morelist.length === 1) {
        // 削除して8件以下になったら「すべて表示」欄そのものが存在しないので強制的にfalse
        this.setState({ childShowMore: false });
      }
      this.props.onDelFavCallBack(name, group);
    }
  }

  render() {
    // 高さの計算　８件以上ある場合は８件分＋「さらに表示」中かどうか、そうでない場合は件数分
    let baseheight =
      this.props.morelist.length > 0
        ? (this.state.childShowMore ? this.props.morelist.length : 0) + this.props.list.length + 1 // 「さらに表示」分
        : this.props.list.length
    let height = baseheight * BASE_FONT_HEIGHT;
    this.childHeight = {
      // 本欄表示がある場合とない場合で分岐
      height: this.state.show ? height + "px" : "0px"
    }

    return (
      <div className={mapModifiers('m-vertical-menu', this.props.modifiers)}>
        <div className="m-vertical-menu__heading">
          <HeadingList type={this.props.icon} isList modifiers={['line', 'show']}>
            {this.props.title}
            <span
              className={mapModifiers('m-vertical-menu__heading__title', !this.state.show ? ["hidden"] : [])}
              onClick={this.clickAllow} ></span>
          </HeadingList>
        </div>

        {this.props.isFavorite && this.props.list.length > 0 ? ( // ReactSortableの引数に空を渡すと落ちるためブロック
          // お気に入りをソート可能にする
          <div style={this.childHeight}
            className={mapModifiers('m-vertical-menu__item', !this.state.show ? ["hidden"] : [])}>

            { /* D&D並び替え用 groupを同じにすることで「さらに表示」とまたいだD&Dが可能 */}
            <ReactSortable group="favgroup" animation={150} ghostClass="blue-background-class" list={this.props.list}
              setList={(newState) => this.setState({ favList: newState })} // ドラッグ中の状態
              onChange={(/*evt*/) => {
                //console.log(evt.newIndex + "番目に来た" + evt.item.textContent)
                this.dropAnother = false; // さらに表示側ではない箇所にドラッグした
                this.orderChanged = true; // 順番の変更があった
              }}
              onEnd={(evt) => { // 元が「すべて表示」以外のものが通知される。またいだ場合でもこちらに通知される
                //console.log((this.dropAnother ? evt.newIndex + (this.props.list.length - 1) : evt.newIndex) + "番目に移動完了"
                //+ evt.clone.textContent + (!this.orderChanged ? "（変更なし）" : "")) // cloneが移動先の要素らしい
                if (evt.clone.textContent && this.orderChanged) { // 親に通知し、ソート後のお気に入り項目をsetStateしてもらう(変更がなければ通知しない)
                  let toIndex = this.dropAnother ? evt.newIndex + (this.props.list.length - 1) : evt.newIndex; // order（さらに表示領域なら＋８）配列長分の調整
                  this.dropAnother = false;
                  this.orderChanged = false;
                  this.props.onSortFavCallBack(evt.clone.textContent.replace("★", ""), toIndex); // 親に通知（★までがメニューになっているので取り除く）
                }
              }}>
              {this.props.list.map((item, index) => (
                <LeftMenuItem key={index} url={item.url} isFavorite={item.isFavorite} targetGroup={item.target_group}
                  onAddFavCallBack={this.callAddFav} onDelFavCallBack={this.callDelFav}>{item.name}</LeftMenuItem>
              ))}
            </ReactSortable>

            {this.props.morelist.length > 0 ? ( // さらに表示欄
              <LeftMenuItem isGroup onEventCallBack={this.setChildShowMore}>

                {/* D&D並び替え用 groupを同じにすることで「さらに表示」とまたいだD&Dが可能 */}
                <ReactSortable group="favgroup" animation={150} ghostClass="blue-background-class" list={this.props.morelist}
                  setList={(newState) => this.setState({ favMoreList: newState })}
                  onChange={(/*evt*/) => {
                    //console.log(evt.newIndex + "番目に来たnew" + evt.item.textContent)
                    this.dropAnother = true; // さらに表示側にドラッグした
                    this.orderChanged = true; // 順番の変更があった
                  }}
                  onEnd={(evt) => { // 元が「すべて表示」内のものが通知される。またいだ場合でもこちらに通知される
                    //console.log((this.dropAnother ? evt.newIndex + (this.props.list.length) : evt.newIndex) + "番目に移動完了new"
                    //+ evt.clone.textContent + (!this.orderChanged ? "（変更なし）" : "")) // cloneが移動先の要素らしい
                    if (evt.clone.textContent && this.orderChanged) { // 親に通知し、ソート後のお気に入り項目をsetStateしてもらう(変更がなければ通知しない)
                      let toIndex = this.dropAnother ? evt.newIndex + (this.props.list.length) : evt.newIndex; // order（さらに表示領域なら＋８）
                      this.dropAnother = false;
                      this.orderChanged = false;
                      this.props.onSortFavCallBack(evt.clone.textContent.replace("★", ""), toIndex); // 親に通知（★までがメニューになっているので取り除く）
                    }
                  }}>
                  {this.props.morelist.map((item, index) => (
                    <LeftMenuItem key={index} url={item.url} isFavorite={item.isFavorite} targetGroup={item.target_group}
                      onAddFavCallBack={this.callAddFav} onDelFavCallBack={this.callDelFav}>{item.name}</LeftMenuItem>
                  ))}
                </ReactSortable>
              </LeftMenuItem>
            ) : []}
          </div>
        ) : (
            // お気に入り以外
            <div style={this.childHeight}
              className={mapModifiers('m-vertical-menu__item', !this.state.show ? ["hidden"] : [])}>
              {this.props.list.map((item, index) => (
                <LeftMenuItem key={index} url={item.url} isFavorite={item.isFavorite}
                  onAddFavCallBack={this.callAddFav} onDelFavCallBack={this.callDelFav}>{item.name}</LeftMenuItem>
              ))}
              {this.props.morelist.length > 0 ?
                <LeftMenuItem isGroup onEventCallBack={this.setChildShowMore}>
                  {this.props.morelist.map((item, index) => (
                    <LeftMenuItem key={index} url={item.url} isFavorite={item.isFavorite}
                      onAddFavCallBack={this.callAddFav} onDelFavCallBack={this.callDelFav}>{item.name}</LeftMenuItem>
                  ))}
                </LeftMenuItem>
                : []}
            </div>
          )
        }
      </div >
    );
  }
};
export default VerticalMenu;
