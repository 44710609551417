import * as React from 'react';
import './Text.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
interface Props extends DefaultProps {
  children?: any;
  // props that come into the component
}

export const Text = (props: Props) => <span className={mapModifiers('a-text', props.modifiers)}>{props.children}</span>;

export default Text;
