import React from 'react';
import './media.scss';
//import Slide from 'components/organisms/Slide/Slide';
import News from 'components/organisms/News/News';
import CardHeader from 'components/molecules/CardHeader/CardHeader';
import TextLink from 'components/atoms/TextLink/TextLink';
import Text from 'components/atoms/Text/Text';
import Pickup from 'components/organisms/Pickup/Pickup';


// eslint-disable-next-line react/display-name
export const Media = () => (
  <div className="media-page">
    <div className="media-page__pickup">
      <Pickup />
    </div>
    {/*
    <div className="media-page__news">
      <News isMedia />
    </div>
    <div className="media-page__media">
      <div className="media-page__media__heading">
        <CardHeader type="SNS">ソーシャルメディア</CardHeader>
      </div>
      <div className="media-page__media__content">
        <div className="media-page__media__content__item">
          <div className="box">
            <div className="box__header">
              <img alt="title" src={require('assets/imgs/adjust-title.png')} />
            </div>
            <div className="box__content">
              {['1', '2', '3', '4'].map(i => (
                <div className="box__content__item" key={i}>
                  <img alt="tmp" src={require(`assets/imgs/tmp${i}.png`)} />
                </div>
              ))}
            </div>
          </div>
          <TextLink href="https://adjust.adastria.co.jp/top/favorite">ADJUSTへ</TextLink>
        </div>
        <div className="media-page__media__content__item media-page__media__content__item--instagram">
          <div className="box">
            <div className="box__header">
              <div className="box__header__left">
                <img alt="icon" src={require(`assets/imgs/chat-bot.svg`)} />
              </div>
              <div className="box__header__right">
                <img alt="logo" src={require(`assets/imgs/logo.svg`)} />
                <Text modifiers={['12', 'bold']}>@adastria_official</Text>
              </div>
            </div>
            <div className="box__content">
              {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(i => (
                <div className="box__content__item" key={i}>
                  <img alt="item" src={require(`assets/imgs/ig${i}.png`)} />
                </div>
              ))}
            </div>
          </div>
          <TextLink href="https://www.instagram.com/adastria_official/">Instagramへ</TextLink>
        </div>
      </div>
    </div>
    */}
  </div>
);

export default Media;
