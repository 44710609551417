import * as firebase from 'firebase/app';
import * as React from 'react';
import './Login.scss';
import { withRouter } from 'react-router-dom';
import { history } from '../../../App';
import Card from 'components/organisms/Card/Card';
import Text from '../../atoms/Text/Text';
import * as comFunc from 'common/functions';

const login = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider);
  comFunc.setCookie("ban_domain", "0");
};

export const Login: React.FC = () => {
  const [isLoading, setLoading] = React.useState(true);
  React.useEffect(() => {
    let cleanedUp = false;

    // ユーザ情報取得
    firebase.auth().onAuthStateChanged(user => {
      if (!cleanedUp) {
        if (user && comFunc.getCookie("ban_domain") !== "1") {
          history.push('/');
        } else {
          setLoading(false);
        }
      }
    });

    const cleanup = () => {
      cleanedUp = true;
    };
    return cleanup;
  });

  return isLoading ? (
    <Text modifiers={['16', 'bold']}>loading...</Text>
  ) : (
      <div className="p-login">
        <div className="p-login__wrapper">
          <div className="p-login__wrapper__content">
            <div className="p-login__wrapper__content__logo">
              <img alt="logo" src={require('assets/imgs/logo.svg')} />
            </div>
            <div className="p-login__wrapper__content__google">
              {/* eslint-disable-next-line */}
              <a onClick={() => login()} target="_blank">
                <Card>
                  <div className="p-login__wrapper__content__google__text">
                    <span>Google</span>
                    <span>でログイン</span>
                  </div>
                </Card>
              </a>
            </div>
          </div>
        </div>
        <div className="p-login__signature">© Adastria Co., Ltd.</div>
      </div>
    );
};

export default withRouter(Login);
