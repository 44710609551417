import * as React from 'react';
import './StoreCardContent.scss';
import { DefaultProps, mapModifiers } from 'components/_utils';
import Icon from 'components/atoms/Icons/Icon';
import Button from 'components/atoms/Button/Button';
interface Props extends DefaultProps {
  children?: any;
  description?: string;
}

export const StoreCardContent = (props: Props) => (
  <div className={mapModifiers('m-storecardcontent', props.modifiers)}>
    <Button typeIcon="more" modifiers={['icon']} size="14" />
    <div className="m-storecardcontent__content">
      <span className="m-storecardcontent__content__title">{props.children}</span>
      {props.description ? (
        <span className="m-storecardcontent__content__description">{`(${props.description})`}</span>
      ) : (
        ''
      )}
    </div>
    <a className="m-storecardcontent__link" href="/">
      <Icon type="arrow-dot" />
    </a>
  </div>
);

export default StoreCardContent;
