import * as React from 'react';
import './HambugerMenu.scss';
import { mapModifiers } from 'components/_utils';
import HeadingList from 'components/molecules/HeadingList/HeadingList';
import FunctionButton from 'components/atoms/FunctionButton/FunctionButton';
import { history } from '../../../App';

interface Props {
  open?: any;
  openFnc?: any;
}

export const HambugerMenu = (props: Props) => (
  <div
    className={mapModifiers('o-hambuger-menu', props.open ? ['show'] : [''])}
    onClick={() => props.openFnc(!props.open)}
  >
    <div className="o-hambuger-menu__symbol">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <>
      <div className="o-hambuger-menu__menu">
        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['mail']} href="https://mail.google.com/" />
          <HeadingList modifiers={['line']}>メール</HeadingList>
        </div>
        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['calendar']} href="https://calendar.google.com/" />
          <HeadingList modifiers={['line']}>カレンダー</HeadingList>
        </div>

        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['hakusyaku']} href="https://app.hitto.chat/zxuQFR5nU2/XJO2rYpaat/chat" />
          <HeadingList modifiers={['line']}>チャットボット</HeadingList>
        </div>

        <div className="o-hambuger-menu__menu__item">
          <FunctionButton modifiers={['agpt']} href="https://www.adst01.smart-generative-chat.com" />
          <HeadingList modifiers={['line']}>AGPT</HeadingList>
        </div>

        <div className="o-hambuger-menu__menu__logout">
          <HeadingList type={'logout'} modifiers={['logout']} onClick={() => { history.push('/logout') }}>
            ログアウト
          </HeadingList>
        </div>
        <div className="o-hambuger-menu__menu__backlayer" onClick={() => props.openFnc(!props.open)}></div>
      </div>
    </>
  </div>
);

export default HambugerMenu;